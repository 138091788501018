import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { SearchPageAttribute, SearchPageField } from '@/shared/lib-api';
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultItemLabel } from '../defaults/default-item-label';

function DefaultListItemInputToggleButton(props: SearchPageField) {
  const { t } = useTranslation();
  var { field, onChange } = useSearchForm<SearchPageAttribute>(props.formName);
  const selectAttributes = React.useMemo(() => {
    return (
      [
        {
          label: t('Labels.' + props.placeholder),
          value: '',
        },
      ] as SearchPageAttribute[]
    ).concat(props.attributes);
  }, [props.attributes, t, props.placeholder]);

  const selectItems = React.useMemo(() => {
    return selectAttributes.map((opt, index) => (
      <ToggleButton key={index} value={opt.value}>
        <Box>
          <Typography variant="body1">{t(opt.label)}</Typography>
        </Box>
      </ToggleButton>
    ));
  }, [selectAttributes, t]);

  const onValueChanged = React.useCallback(
    //togglebutton sends 'null', if alle options are untoggled
    (event: React.MouseEvent<HTMLElement>, value: string | null) => {
      if (value === null) {
        return;
      }
      let result = selectAttributes.find(p => p.value === value);
      onChange(result);
    },
    [onChange, selectAttributes],
  );
  return (
    <>
      {props.displayHeadline && (
        <DefaultItemLabel header={t(props.subHeader)} />
      )}
      <ToggleButtonGroup
        fullWidth
        exclusive
        onChange={onValueChanged}
        value={field.value}>
        {selectItems}
      </ToggleButtonGroup>
    </>
  );
}

export default React.memo(DefaultListItemInputToggleButton);
