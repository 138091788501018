import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export function useToggle<T>(
  initState: T,
): [Dispatch<SetStateAction<T>>, MutableRefObject<T>] {
  const valueRef = useRef(initState);
  const setValue = useCallback((value: T) => {
    valueRef.current = value;
  }, []);

  return [setValue, valueRef];
}

export function useMutableValue<T>(initState: T): [T, MutableRefObject<T>] {
  const value = useMemo(() => {
    return initState;
  }, [initState]);
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return [value, valueRef];
}
