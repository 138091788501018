import { Box, Divider, Grid, ListItem, Typography } from '@mui/material';
import Image from '@/web/views/components/image/image';
import addicon from '@/shared/icons/add-icon.svg';
import React from 'react';
import { useSearchContext } from '../../../SRP/search-section';

interface PropsFromParent {
  name: string;
  onAddToArray: () => void;
}

export function AddToArray(props: PropsFromParent) {
  const { mode } = useSearchContext();
  return (
    <Grid item xs={12}>
      <ListItem
        style={{ cursor: 'pointer', paddingTop: '14px' }}
        onClick={props.onAddToArray}>
        <Image
          alt="Tilføj søgekriterie"
          priority={false}
          src={addicon.src}
          width={18}
          height={18}
        />
        <Typography paddingLeft={1} fontSize={14} color={'#1066B6'}>
          {props.name}
        </Typography>
      </ListItem>
      {mode !== 'screen' && <Divider style={{ paddingTop: '14px' }} />}
      <Box paddingTop={'10px'} />
    </Grid>
  );
}
