import { Box, Divider, Grid, ListItem, Typography } from '@mui/material';
import Image from '@/web/views/components/image/image';
import crossicon from '@/shared/icons/cross-srp-icon.svg';
import React from 'react';
import {
  GenerateTagRemovable,
  GetMakeModelText,
} from '../../../SRP/search-bar-srp';
import { useTranslation } from 'react-i18next';
import { useSearchContext } from '../../../SRP/search-section';

interface PropsFromParent<T> {
  data: T[];
  onRemoveFromArray: (index: number) => void;
}

export function ListArray<T>(props: PropsFromParent<T>) {
  const { mode } = useSearchContext();
  const { t } = useTranslation();
  return (
    <>
      {mode === 'screen' ? (
        <Grid item xs={12}>
          <Grid container paddingY={1} rowSpacing={1} columnSpacing={1}>
            {Object.entries(
              props.data.filter((p, i) => i !== props.data.length - 1),
            ).map(
              GenerateTagRemovable(
                t,
                false,
                undefined,
                props.onRemoveFromArray,
              ),
            )}
          </Grid>
        </Grid>
      ) : (
        props.data &&
        props.data
          .filter((p, i) => i !== props.data.length - 1)
          .map((p, i) => (
            <Grid item xs={12} key={i}>
              <ListItem>
                <Box
                  display="flex"
                  flexGrow={1}
                  justifyContent={'space-between'}
                  alignItems="center">
                  <Typography paddingLeft={1} fontSize={14}>
                    {GetMakeModelText(p)}
                  </Typography>
                  <Image
                    style={{ cursor: 'pointer' }}
                    alt="Fjern søgekriterie"
                    priority={false}
                    onClick={() => props.onRemoveFromArray(i)}
                    src={crossicon.src}
                    width={18}
                    height={18}
                  />
                </Box>
              </ListItem>
              <Divider />
            </Grid>
          ))
      )}
    </>
  );
}
