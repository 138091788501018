import { Box } from '@mui/material';
import geoLocationIcon from '@/shared/icons/geolocation-icon.svg';
import Image from '@/web/views/components/image/image';
import React from 'react';

export const GeolocationIcon = () => (
  <Box
    width={18}
    height={18}
    alignItems="center"
    display={'flex'}
    justifyContent="center">
    <Image
      alt="Hent lokationsdata"
      priority={false}
      src={geoLocationIcon.src}
      width={18}
      height={18}
    />
  </Box>
);
