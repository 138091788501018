import { Box, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Image from '@/web/views/components/image/image';
import * as React from 'react';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import collapseIcon from '../../../../shared/icons/collapse-icon.svg';
import expandIcon from '../../../../shared/icons/expand-icon.svg';
import { BlocksVisible } from '../SRP/search-content';

export function CollapseExpandItem(props: {
  setBlocksVisible: React.Dispatch<React.SetStateAction<BlocksVisible>>;
  BlocksVisible: BlocksVisible;
  itemKey: keyof BlocksVisible;
  label: string;
  first?: boolean;
  children?: ReactNode;
}) {
  const onBoxClicked = useCallback(() => {
    props.setBlocksVisible({
      ...props.BlocksVisible,
      [props.itemKey]: props.BlocksVisible[props.itemKey] ? false : true,
    });
  }, [props]);
  const { t } = useTranslation();
  return (
    <>
      {!props.first && <Divider />}
      <Box
        style={{ cursor: 'pointer' }}
        display={'flex'}
        flexDirection={'row'}
        paddingTop={'15px'}
        paddingBottom={props.BlocksVisible[props.itemKey] ? '0px' : '15px'}
        alignItems={'center'}
        onClick={onBoxClicked}
        justifyContent={'space-between'}>
        <Typography fontWeight={700} color={'primary'}>
          {t(props.label)}
        </Typography>
        <Image
          alt="Udvid/fold sammen"
          priority={false}
          src={
            props.BlocksVisible[props.itemKey]
              ? expandIcon.src
              : collapseIcon.src
          }
          width={18}
          height={18}
        />
      </Box>
      {props.BlocksVisible[props.itemKey] && props.children}
    </>
  );
}
