import { Box, MenuItem } from '@mui/material';
import * as React from 'react';

export interface PropsFromParent {
  label: string;
  itemValue: string;
  isGroup: boolean;
  idValue?: string;
  isDisabled: boolean;
  isMobile?: boolean;
  autoCompleteProps?: React.HTMLAttributes<HTMLLIElement>;
  hasGroupFields: boolean;
  disableGroupsOnSyi?: boolean;
}

export function DefaultMenuItem(props: PropsFromParent) {
  const style = {
    height: props.isMobile ? 40 : 24,
    marginTop: props.isMobile ? 0 : 4,
    minHeight: 'unset',
  };
  if (props.disableGroupsOnSyi && props.idValue && props.idValue === '0') {
    return <UnClickableGroupMenuItem {...props} style={style} />;
  } else if (props.isGroup && !props.isDisabled) {
    return <ClickableGroupMenuItem {...props} style={style} />;
  } else if (props.isDisabled) {
    return <UnClickableGroupMenuItem {...props} style={style} />;
  } else {
    return (
      <MenuItem
        {...props.autoCompleteProps}
        style={style}
        value={props.itemValue}>
        <Box paddingLeft={props.hasGroupFields ? '12.5px' : '0px'}>
          {props.label}
        </Box>
      </MenuItem>
    );
  }
}

export const ClickableGroupMenuItem: React.FC<
  PropsFromParent & { style: React.CSSProperties }
> = props => {
  return (
    <MenuItem
      style={props.style}
      {...props.autoCompleteProps}
      value={props.itemValue}>
      <Box paddingLeft={0}>{props.label}</Box>
    </MenuItem>
  );
};

export const UnClickableGroupMenuItem: React.FC<
  PropsFromParent & { style: React.CSSProperties }
> = props => {
  return (
    <MenuItem
      disabled
      {...props.autoCompleteProps}
      style={props.style}
      value={props.itemValue}>
      <Box paddingLeft={0}>{props.label}</Box>
    </MenuItem>
  );
};
