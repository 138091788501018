import {
  FormValue,
  useImmidiateSearchForm,
  useSearchForm,
} from '@/shared/hooks/search-form-field-hook';
import {
  SearchPageField,
  SearchPageMakeModelVariantOption,
} from '@/shared/lib-api';
import { Grid, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { ArrayInput } from '../default-components/input/input-array-helpers/array-input';
import { SearchMode, SortFilterAndGenerateFields } from './search-section';

interface PropsFromParent {
  value: SearchPageField;
  index: number;
  mode: SearchMode;
}

export const SearchGroupField: React.FC<PropsFromParent> = props => {
  var { field: makeModelVariantOptions, onChange } = useImmidiateSearchForm<
    SearchPageMakeModelVariantOption[]
  >('makeModelVariantOptions');
  const onRemoveFromList = useCallback(
    (value: SearchPageMakeModelVariantOption[], formNameOverride?: string) => {
      onChange(value, formNameOverride);
    },
    [onChange],
  );

  const onAddToList = useCallback(
    (value: SearchPageMakeModelVariantOption[]) => {
      onChange(value, undefined, false);
    },
    [onChange],
  );

  return (
    <Grid
      container
      columnSpacing={
        props.mode === 'frontpage' ? 2.5 : props.mode === 'seo-srp' ? 0 : 2
      }>
      {props.mode !== 'frontpage' && props.mode !== 'seo-srp' ? (
        <Grid key={props.index} item xs={12}>
          <ArrayInput
            mode={props.mode}
            newItem={
              {
                brand: { value: '', label: '' },
                model: { value: '', label: '' },
                variant: '',
              } as SearchPageMakeModelVariantOption
            }
            listItems={makeModelVariantOptions}
            onRemove={onRemoveFromList}
            onAdd={onAddToList}>
            <SearchGroupFieldData
              value={props.value}
              mode={props.mode}
              makeModelVariantOptions={makeModelVariantOptions}
            />
          </ArrayInput>
        </Grid>
      ) : (
        <SearchGroupFieldData
          value={props.value}
          mode={props.mode}
          makeModelVariantOptions={makeModelVariantOptions}
        />
      )}
    </Grid>
  );
};

interface SearchGroupFieldDataProps {
  value: SearchPageField;
  mode: SearchMode;
  makeModelVariantOptions: SearchPageMakeModelVariantOption[];
}

export function SearchGroupFieldData(props: SearchGroupFieldDataProps) {
  var { field: categoryField } = useSearchForm('category');
  return (
    <>
      {SortFilterAndGenerateFields({
        searchFields: props.value.groupedFields.map<SearchPageField>(p => {
          return {
            ...p,
            formName: `${props.value.formName}.${
              props.makeModelVariantOptions?.length - 1
            }.${p.formName}`,
          };
        }),
        categoryField: categoryField,
        mode: props.mode,
      })}
    </>
  );
}
