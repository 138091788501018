import { useScreenSizeContext } from '@/pages/_app';
import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { SearchPageAttribute, SearchPageField } from '@/shared/lib-api';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { PadBox } from '../containers/pad-box';
import { CollapseExpandItem } from '../default-components/collapse-expand-item';
import { BlocksVisible } from './search-content';
import GenerateSearchField from './search-field';

interface SearchSRPProps {
  setBlocksVisible: React.Dispatch<React.SetStateAction<BlocksVisible>>;
  BlocksVisible: BlocksVisible;
  itemKey: keyof BlocksVisible;
  label: string;
  first?: boolean;
  fieldsInSection?: SearchPageField[];
  displayHalfWidth?: boolean;
  mode: SearchMode;
}

export type SearchMode = 'screen' | 'sidebar' | 'frontpage' | 'syi' | 'seo-srp';

const SearchSRPSection: React.FC<SearchSRPProps> = props => {
  var { field: categoryField } = useSearchForm<SearchPageAttribute>('category');
  var { field: fuelTypeField } =
    useSearchForm<SearchPageAttribute[]>('fuelTypes');
  return (
    <CollapseExpandItem {...props}>
      <SearchSection
        categoryField={categoryField}
        fieldsInSection={props.fieldsInSection}
        displayHalfWidth={props.displayHalfWidth}
        fuelTypeValue={fuelTypeField}
        mode={props.mode}
        isCategoryField={props.itemKey === 'Category'}
      />
    </CollapseExpandItem>
  );
};

export default SearchSRPSection;

export const SRPSection: React.FC<SearchSectionProps> = props => {
  var { field: categoryField } = useSearchForm<SearchPageAttribute>('category');
  return <SearchSection {...props} categoryField={categoryField} />;
};

interface SearchSYIProps {
  title: string;
  subTitle?: string;
  isCategoryField?: boolean;
  fieldsInSection: SearchPageField[];
  fuelTypeValue?: SearchPageAttribute;
  trailerHitchValue?: SearchPageAttribute;
  displayBudValue?: SearchPageAttribute[];
}

export const SYISection: React.FC<SearchSYIProps> = props => {
  const {
    input: { value: category },
  } = useField<SearchPageAttribute>('category');
  return (
    <PadBox>
      <Typography variant="h5">{props.title}</Typography>
      {props.subTitle && <Typography>{props.subTitle}</Typography>}
      <SearchSection
        mode={'syi'}
        isCategoryField={props.isCategoryField ?? false}
        categoryField={category}
        fieldsInSection={props.fieldsInSection}
        displayBudValue={props.displayBudValue}
        fuelTypeValue={props.fuelTypeValue}
        trailerHitchValue={props.trailerHitchValue}
      />
    </PadBox>
  );
};

interface SearchSectionProps {
  isCategoryField?: boolean;
  fieldsInSection?: SearchPageField[];
  displayHalfWidth?: boolean;
  mode: SearchMode;
  submitSearch?: () => void;
  totalResults?: number;
  fuelTypeValue?: SearchPageAttribute | SearchPageAttribute[];
  trailerHitchValue?: SearchPageAttribute;
  displayBudValue?: SearchPageAttribute[];
}

interface SearchSectionInternalProps extends SearchSectionProps {
  categoryField: SearchPageAttribute;
}

interface SearchContextProps {
  mode?: SearchMode;
  fuelTypeValue?: SearchPageAttribute | SearchPageAttribute[];
  trailerHitchValue?: SearchPageAttribute;
  displayBudValue?: SearchPageAttribute[];
}

const SearchContext = createContext<SearchContextProps>({});
export const useSearchContext = () => {
  const context = useContext(SearchContext);
  return context;
};

const SearchSection: React.FC<SearchSectionInternalProps> = props => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSizeContext();
  const columnSpacing = useMemo(() => {
    if (props.mode === 'frontpage') {
      return '20px';
    } else if (props.mode === 'syi') {
      return '10px';
    } else if (props.mode === 'seo-srp') {
      return '0px';
    }
    return props.displayHalfWidth && props.mode !== 'screen' ? 0.5 : 2.5;
  }, [props.mode, props.displayHalfWidth]);

  const contextProps: SearchContextProps = useMemo(() => {
    return {
      mode: props.mode,
      fuelTypeValue: props.fuelTypeValue,
      displayBudValue: props.displayBudValue,
      trailerHitchValue: props.trailerHitchValue,
    };
  }, [
    props.mode,
    props.fuelTypeValue,
    props.displayBudValue,
    props.trailerHitchValue,
  ]);

  const children = useCallback(() => {
    let fields = props.isCategoryField
      ? props.fieldsInSection.map((value, index, array) => (
          <GenerateSearchField
            key={value.formName}
            array={array}
            value={value}
            index={index}
            mode={props.mode}
          />
        ))
      : SortFilterAndGenerateFields({
          searchFields: props.fieldsInSection,
          categoryField: props.categoryField,
          mode: props.mode,
        });
    return (
      <SearchContext.Provider value={{ ...contextProps }}>
        <Grid container alignItems={'center'} columnSpacing={columnSpacing}>
          {fields}
          {props.submitSearch && props.mode === 'frontpage' && (
            <Grid item xs={12} sm={6}>
              <Box paddingY="10px">
                <Button
                  sx={{ height: 40, marginBottom: '1px' }}
                  fullWidth
                  color="buttonBlue"
                  onClick={props.submitSearch}>
                  {t('SearchCard.SeeResults' + (isMobile ? 'Mobile' : ''), {
                    amount: thousandNumberSeperator(props.totalResults),
                  })}
                </Button>
              </Box>
            </Grid>
          )}
          {props.submitSearch && props.mode === 'seo-srp' && (
            <Grid item xs={12} md={1.2}>
              <Button
                id="seo-srp-search"
                sx={{
                  height: isMobile ? 46 : 70,
                  fontSize: '18px',
                  borderRadius: '10px',
                }}
                fullWidth
                color="buttonBlue"
                onClick={props.submitSearch}>
                {t('Labels.Search')}
              </Button>
            </Grid>
          )}
        </Grid>
      </SearchContext.Provider>
    );
  }, [
    props.fieldsInSection,
    props.categoryField,
    contextProps,
    props.mode,
    props.isCategoryField,
    props.totalResults,
    props.submitSearch,
    columnSpacing,
    t,
    isMobile,
  ]);
  return children();
};

export const SortFilterAndGenerateFields: React.FC<{
  searchFields: SearchPageField[];
  categoryField: SearchPageAttribute;
  mode: SearchMode;
}> = ({ searchFields, categoryField, mode }) => {
  return (
    <>
      {searchFields
        .filter(p =>
          p.categoryName.includes(
            categoryField === undefined || categoryField?.value === ''
              ? 'brugte-biler'
              : categoryField.value,
          ),
        )
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((value, index, array) => (
          <GenerateSearchField
            key={value.formName}
            array={array}
            value={value}
            index={index}
            mode={mode}
          />
        ))}
    </>
  );
};
