import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import {
  FacetType,
  SearchPageAttribute,
  SearchPageField,
} from '@/shared/lib-api';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultItemInputCheckBoxes from '../defaults/default-item-input-checkbox';
import { DefaultItemLabel } from '../defaults/default-item-label';

function DefaultListItemInputCheckBoxes(props: SearchPageField) {
  const { field, onChange, fieldExamples } = useSearchForm<
    SearchPageAttribute[]
  >(props.formName, 600);
  const { t } = useTranslation();

  const onChangeValue = React.useCallback(
    (value: SearchPageAttribute[]) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <>
      {props.displayHeadline && (
        <DefaultItemLabel header={t(props.subHeader)} />
      )}
      <DefaultItemInputCheckBoxes
        onChange={onChangeValue}
        attributes={props.attributes}
        examples={fieldExamples}
        fieldValue={field}
        displayAllExamples={props.facetType === FacetType.MultiSelectUnion}
      />
    </>
  );
}

export default React.memo(DefaultListItemInputCheckBoxes);
