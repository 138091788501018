import { useScreenSizeContext } from '@/pages/_app';
import { FieldUsage, SearchPageAttribute } from '@/shared/lib-api';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '../../input-icons/crossIcon';
import { DropDownArrow } from '../../input-icons/dropdownIcon';
import DefaultInputError from '../default-input-error';
import {
  disabledFieldlabels,
  filterOptionsWithSynonyms,
} from '../default-item-input-autocomplete';
import { DefaultMenuItem } from '../selects/default-menu-item';

interface PropsFromParent {
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: SearchPageAttribute,
  ) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onInput?: React.FormEventHandler<HTMLDivElement>;
  value: SearchPageAttribute;
  placeholder: string;
  attributes: SearchPageAttribute[];
  fieldUsage: FieldUsage;
  error?: string;
  freeSolo?: boolean;
}

export function SeoItemInputAutocomplete(props: PropsFromParent) {
  const selectItems = useMemo(() => {
    let result = [...props.attributes];
    return result;
  }, [props.attributes]);
  const { isMobile } = useScreenSizeContext();

  const hasGroupFields = useMemo(() => {
    let result = props.attributes.some(p => p.isGroup);
    return result;
  }, [props.attributes]);

  const isDisabled = useMemo(() => {
    return !selectItems || selectItems.length === 0;
  }, [selectItems]);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly SearchPageAttribute[]>(
    [],
  );
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions([...selectItems]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      <Autocomplete
        sx={{
          borderRight:
            isMobile || props.placeholder === 'FrontYear.From'
              ? 'unset'
              : '1px solid #C6C6C6',
          borderBottom:
            !isMobile || props.placeholder === 'FrontYear.From'
              ? 'unset'
              : '1px solid #C6C6C6',
          backgroundColor: !isDisabled ? 'none' : '#F5F5F5',
          borderRadius: '2px',
          height: isMobile ? 46 : 70,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disabled={isDisabled}
        noOptionsText={'Ingen'}
        onBlur={props.onBlur}
        onInput={props.onInput}
        clearIcon={CrossIcon}
        value={props.value}
        filterOptions={filterOptionsWithSynonyms()}
        selectOnFocus
        forcePopupIcon
        freeSolo={
          props.freeSolo !== undefined
            ? props.freeSolo
            : //@ts-ignore
            props.value === undefined || props.value === ''
            ? false
            : true
        }
        onChange={props.onChange}
        options={options}
        PopperComponent={props => (
          <Popper
            {...{
              ...props,
              placeholder: '',
              style: {
                ...props.style,
                paddingTop: isMobile ? 15 : 28,
                borderRadius: '10px',
              },
            }}
          />
        )}
        PaperComponent={props => (
          <Paper
            {...{
              ...props,
              style: {
                ...props.style,
                borderRadius: '10px',
              },
            }}>
            {props.children}
          </Paper>
        )}
        ListboxComponent={props => (
          <Box
            {...props}
            style={{
              paddingTop: '5px',
              paddingLeft: '1px',
              borderRadius: '10px',
            }}
          />
        )}
        renderOption={(autoCompleteProps, item, state) => (
          <DefaultMenuItem
            isMobile={isMobile}
            disableGroupsOnSyi={props.fieldUsage === FieldUsage.Create}
            idValue={item.idValue}
            isDisabled={disabledFieldlabels.includes(item.label)}
            autoCompleteProps={autoCompleteProps}
            key={selectItems.indexOf(item)}
            itemValue={item.value}
            isGroup={item.isGroup}
            label={item.label}
            hasGroupFields={hasGroupFields}
          />
        )}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.label
        }
        renderInput={params => (
          <GenerateTextInput
            props={params}
            isMobile={isMobile}
            placeholder={props.placeholder}
          />
        )}
      />
      <DefaultInputError error={props.error} />
    </>
  );
}
const GenerateTextInput = (props: {
  props: AutocompleteRenderInputParams;
  placeholder: string;
  isMobile: boolean;
}) => {
  const elementToUse = React.useMemo(() => {
    return (
      props.props.InputProps.endAdornment as JSX.Element
    )?.props.children.find(
      child =>
        child !== null &&
        (props.props.inputProps.value &&
        (props.props.inputProps as any).value.length > 0
          ? child.props.title === 'Clear'
          : child.props.title === 'Open'),
    );
  }, [props.props.InputProps.endAdornment, props.props.inputProps]);
  const { t } = useTranslation();
  const onClearButtonClicked = React.useCallback(
    (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      elementToUse?.props.onClick(ev);
    },
    [elementToUse],
  );
  const onDropdownButtonClicked = React.useCallback(
    (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      elementToUse?.props.onClick(ev);
    },
    [elementToUse],
  );
  const InputProp = React.useMemo(() => {
    return {
      ...props.props.InputProps,
      endAdornment:
        (props.props.inputProps as any).value &&
        (props.props.inputProps as any).value.length > 0 ? (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            position="absolute"
            right={props.isMobile ? '8px' : '36px'}>
            <IconButton
              style={{ padding: '2px' }}
              onClick={onClearButtonClicked}>
              {CrossIcon}
            </IconButton>
          </Box>
        ) : (
          <Box
            position="absolute"
            right={props.isMobile ? '8px' : '36px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <IconButton
              onClick={onDropdownButtonClicked}
              style={{ padding: '2px' }}
              size="medium">
              {DropDownArrow}
            </IconButton>
          </Box>
        ),
    };
  }, [onClearButtonClicked, props, onDropdownButtonClicked]);
  return (
    <TextField
      {...props.props}
      inputProps={{
        ...props.props.inputProps,
        style: {
          marginLeft: props.isMobile ? '0px' : '28px',
          fontSize: '18px',
        },
      }}
      fullWidth={true}
      InputProps={InputProp}
      placeholder={t('Labels.' + props.placeholder)}
    />
  );
};
