import { setCoords, setZipCode } from '@/shared/store/reducers/AuthSlice';
import { useApi } from '@/shared/util/api';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GeolocationIcon } from './input-icons/GeolocationIcon';

export interface GeoLocationButtonProps {
  onChange: (value: string) => void;
}

const geoLocationStyle: React.CSSProperties = {
  cursor: 'pointer',
};

export const GeoLocationButton: FC<GeoLocationButtonProps> = props => {
  const dispatch = useDispatch();
  const { geoLocationApi } = useApi();

  const onLocationGet = useCallback(
    async (position: GeolocationPosition) => {
      let lat = position.coords.latitude;
      let lon = position.coords.longitude;
      dispatch(
        setCoords({
          lat: lat,
          long: lon,
        }),
      );
      try {
        let zipCode =
          await geoLocationApi.apiGeolocationGetzipcodefromlatlongPost({
            latLonRequest: {
              lat,
              lon,
            },
          });
        let parsedZipCode = Number.parseInt(zipCode, 10);
        if (!Number.isNaN(parsedZipCode)) {
          dispatch(setZipCode(parsedZipCode));
        }

        return zipCode;
      } catch {}
    },
    [dispatch, geoLocationApi],
  );

  const getLocationData = useCallback(async () => {
    navigator.geolocation.getCurrentPosition(async position => {
      let zipCode = await onLocationGet(position);
      if (zipCode !== undefined) {
        props.onChange(zipCode);
      }
    });
  }, [onLocationGet, props]);

  return (
    <Box paddingRight={1} style={geoLocationStyle} onClick={getLocationData}>
      <GeolocationIcon />
    </Box>
  );
};
