import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { SearchPageAttribute, SearchPageField } from '@/shared/lib-api';
import { initialSearchAttribute } from '@/shared/store/reducers/SRPSlice';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultItemInputAutocomplete from '../defaults/default-item-input-autocomplete';
import { DefaultItemLabel } from '../defaults/default-item-label';

function DefaultListItemInputAutocomplete(props: SearchPageField) {
  const { t } = useTranslation();
  var { field, onChange } = useSearchForm<SearchPageAttribute>(props.formName);
  const onValueChanged = React.useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: SearchPageAttribute,
    ) => {
      onChange(value?.value ? value : initialSearchAttribute);
      if (value?.value) {
        event.target.focus();
      }
    },
    [onChange],
  );

  return (
    <>
      {props.displayHeadline && (
        <DefaultItemLabel header={t(props.subHeader)} />
      )}
      <DefaultItemInputAutocomplete
        onChange={onValueChanged}
        value={field}
        placeholder={props.placeholder}
        fieldUsage={props.fieldUsage}
        attributes={props.attributes}
      />
    </>
  );
}

export default React.memo(DefaultListItemInputAutocomplete);
