import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { SearchPageAttribute, SearchPageField } from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultItemLabel } from '../defaults/default-item-label';

function DefaultListItemInputRadio(
  props: SearchPageField & { mode: 'screen' | 'sidebar' | 'frontpage' },
) {
  const { t } = useTranslation();
  var { field, onChange, fieldExamples } = useSearchForm<SearchPageAttribute>(
    props.formName,
  );
  const { mode } = props;
  const selectAttributes = React.useMemo(() => {
    return (
      [
        {
          label: t('Labels.' + props.placeholder),
          value: '',
        },
      ] as SearchPageAttribute[]
    ).concat(props.attributes);
  }, [props.attributes, t, props.placeholder]);

  const selectItems = React.useMemo(() => {
    return selectAttributes.map((opt, index) => (
      <Grid key={index} item xs={12} sm={mode === 'screen' ? 3 : 12}>
        <FormControlLabel
          value={opt.value}
          label={
            <Box
              display="flex"
              alignItems="center"
              justifyContent={'space-around'}>
              <Typography variant="body1">{t(opt.label)}</Typography>
              <Typography color={Colors.Gray} variant="body1" marginLeft={2}>
                ({fieldExamples.find(p => p.value === opt.value)?.count ?? 0})
              </Typography>
            </Box>
          }
          control={<Radio size="small" />}
        />
      </Grid>
    ));
  }, [selectAttributes, t, fieldExamples, mode]);

  const onValueChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let result = selectAttributes.find(p => p.value === event.target.value);
      onChange(result);
    },
    [onChange, selectAttributes],
  );
  return (
    <>
      {props.displayHeadline && (
        <DefaultItemLabel header={t(props.subHeader)} />
      )}
      <FormControl>
        <RadioGroup onChange={onValueChanged} value={field.value}>
          <Grid container alignItems="center">
            {selectItems}
          </Grid>
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default React.memo(DefaultListItemInputRadio);
