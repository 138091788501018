import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { SearchPageAttribute, SearchPageField } from '@/shared/lib-api';
import { SRPFieldAttributeSortOrder } from '@/shared/lib-api/models/SRPFieldAttributeSortOrder';
import { initialSearchAttribute } from '@/shared/store/reducers/SRPSlice';
import { Grid } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultItemInputAutocomplete from '../defaults/default-item-input-autocomplete';
import { DefaultItemLabel } from '../defaults/default-item-label';

function DefaultListItemInputAutosuggestToFrom(
  props: SearchPageField & { disableMulti?: boolean },
) {
  var { field, onChange } = useSearchForm<SearchPageAttribute>(
    props.formName,
    600,
  );

  var { field: fieldSecondary, onChange: onChangeSecondary } =
    useSearchForm<SearchPageAttribute>(props.formNameSecondary, 600);

  const { t } = useTranslation();

  const generateSelectItems = React.useCallback(
    (secondary: boolean, oppositeSelectedValue: SearchPageAttribute) => {
      let result = props.attributes;
      if (
        !props.disableAutoFilteringValues &&
        props.formNameSecondary !== undefined
      ) {
        if (oppositeSelectedValue) {
          let indexOfSelectedValue = result.findIndex(
            p => p.value === oppositeSelectedValue.value,
          );
          if (
            props.attributeSortOrder === SRPFieldAttributeSortOrder.NameDesc
          ) {
            result = secondary
              ? result.slice(
                  0,
                  indexOfSelectedValue === -1
                    ? undefined
                    : indexOfSelectedValue + 1,
                )
              : result.slice(
                  indexOfSelectedValue === -1 ? 0 : indexOfSelectedValue,
                );
          } else {
            result = secondary
              ? result.slice(indexOfSelectedValue + 1)
              : result.slice(0, indexOfSelectedValue);
          }
        }
      }
      return result;
    },
    [
      props.attributes,
      props.disableAutoFilteringValues,
      props.attributeSortOrder,
      props.formNameSecondary,
    ],
  );

  const selectItems = React.useMemo(() => {
    let oppositeSelectedValue = fieldSecondary;
    let result = generateSelectItems(
      false,
      oppositeSelectedValue as SearchPageAttribute,
    );
    return result;
  }, [generateSelectItems, fieldSecondary]);

  const selectItemsSecondary = React.useMemo(() => {
    let oppositeSelectedValue = field;
    let result = generateSelectItems(
      true,
      oppositeSelectedValue as SearchPageAttribute,
    );

    return result;
  }, [generateSelectItems, field]);

  const onEdit = React.useCallback(
    (
      value: SearchPageAttribute | string,
      onChangeValue: (
        value: SearchPageAttribute,
        formNameOverride?: string,
      ) => void,
    ) => {
      if (typeof value !== 'string') {
        onChangeValue(value ?? initialSearchAttribute);
      } else {
        let postFixAttribute =
          props.attributes[0].label.split(' ').length === 1
            ? ''
            : props.attributes[0].label.split(' ')[1];
        let numberVal = value
          .replace(new RegExp('\\D', 'gm'), '')
          .replace(postFixAttribute, '')
          .replace('.', '')
          .replace(' ', '')
          .trim();
        if (Number.isNaN(Number.parseInt(numberVal))) {
          onChangeValue(initialSearchAttribute);
        } else {
          onChangeValue({
            value: numberVal,
            label: numberVal + ' ' + postFixAttribute,
          });
        }
      }
    },
    [props.attributes],
  );

  const onValueChanged = React.useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: SearchPageAttribute | string,
    ) => {
      onEdit(value, onChange);

      if (typeof value !== 'string') {
        event.target.focus();
      }
    },
    [onChange, onEdit],
  );

  const onValueBlur = React.useCallback(
    (event: React.FocusEvent<HTMLDivElement, Element>) => {
      let postFixAttribute =
        props.attributes[0].label.split(' ').length === 1
          ? ''
          : props.attributes[0].label.split(' ')[1];
      let numberVal = (event.target as any).value
        .replace(postFixAttribute, '')
        .replace('.', '')
        .trim();
      if (field === undefined || numberVal !== field.value) {
        onEdit((event.target as any).value, onChange);
      }
    },
    [onChange, onEdit, field, props.attributes],
  );

  const onValueChangedSecondary = React.useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: SearchPageAttribute | string,
    ) => {
      onEdit(value, onChangeSecondary);

      if (typeof value !== 'string') {
        event.target.focus();
      }
    },
    [onChangeSecondary, onEdit],
  );

  const onValueBlurSecondary = React.useCallback(
    (event: React.FocusEvent<HTMLDivElement, Element>) => {
      let postFixAttribute =
        props.attributes[0].label.split(' ').length === 1
          ? ''
          : props.attributes[0].label.split(' ')[1];
      let numberVal = (event.target as any).value
        .replace(postFixAttribute, '')
        .replace('.', '')
        .trim();
      if (fieldSecondary === undefined || numberVal !== fieldSecondary.value) {
        onEdit((event.target as any).value, onChangeSecondary);
      }
    },
    [onChangeSecondary, onEdit, fieldSecondary, props.attributes],
  );

  return (
    <>
      {props.displayHeadline && (
        <DefaultItemLabel header={t(props.subHeader)} />
      )}

      <Grid container columnSpacing={0.5}>
        <Grid item xs={props.formNameSecondary && props.attributes ? 6 : 12}>
          <DefaultItemInputAutocomplete
            onInput={onValueBlur}
            onChange={onValueChanged}
            value={field}
            placeholder={
              props.placeholder === '' ? 'From' : props.placeholder + '.From'
            }
            fieldUsage={props.fieldUsage}
            attributes={selectItems}
            freeSolo
          />
        </Grid>

        {props.formNameSecondary && props.attributes && (
          <Grid item xs={6}>
            <DefaultItemInputAutocomplete
              onInput={onValueBlurSecondary}
              onChange={onValueChangedSecondary}
              value={fieldSecondary}
              placeholder={
                props.placeholder === '' ? 'To' : props.placeholder + '.To'
              }
              fieldUsage={props.fieldUsage}
              attributes={selectItemsSecondary}
              freeSolo
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default React.memo(DefaultListItemInputAutosuggestToFrom);
