import { SearchPageAttribute, SearchPageField } from '@/shared/lib-api';
import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import DefaultInputError from '../defaults/default-input-error';
import { DefaultItemLabel } from '../defaults/default-item-label';
import DefaultItemInputSelect from '../defaults/selects/default-item-input-select';

const initialMonth: SearchPageAttribute = { value: '', label: 'Måned' };
const monthAttributes: SearchPageAttribute[] = [
  initialMonth,
  { value: '0', label: 'Januar' },
  { value: '1', label: 'Februar' },
  { value: '2', label: 'Marts' },
  { value: '3', label: 'April' },
  { value: '4', label: 'Maj' },
  { value: '5', label: 'Juni' },
  { value: '6', label: 'Juli' },
  { value: '7', label: 'August' },
  { value: '8', label: 'September' },
  { value: '9', label: 'Oktober' },
  { value: '10', label: 'November' },
  { value: '11', label: 'December' },
];

const initialYear: SearchPageAttribute = { value: '', label: 'År' };
const generateYears: () => SearchPageAttribute[] = () => {
  let result: SearchPageAttribute[] = [initialYear];
  let currentYear = new Date().getFullYear();
  for (var i = currentYear; i >= 1900; i--) {
    result.push({ value: i.toString(), label: i.toString() });
  }
  return result;
};

export function FinalFormItemInputDatePicker(props: SearchPageField) {
  const {
    input: { value, onChange },
    meta,
  } = useField<Date | ''>(props.formName);
  const months = React.useMemo(() => {
    return monthAttributes;
  }, []);
  const years = React.useMemo(() => {
    return generateYears();
  }, []);
  const [month, setMonth] = React.useState<SearchPageAttribute>(
    value !== ''
      ? months.find(p => p.value === value.getMonth().toString())
      : initialMonth,
  );
  const [year, setYear] = React.useState<SearchPageAttribute>(
    value !== ''
      ? years.find(p => p.value === value.getFullYear().toString())
      : initialYear,
  );

  React.useEffect(() => {
    if (value !== '') {
      setMonth(months.find(p => p.value === value.getMonth().toString()));
      setYear(years.find(p => p.value === value.getFullYear().toString()));
    }
  }, [value, setMonth, setYear, years, months]);

  const { t } = useTranslation();

  const onMonthChange = React.useCallback(
    (monthValue: SearchPageAttribute) => {
      setMonth(monthValue);
      if (monthValue !== initialMonth && year !== initialYear) {
        let result = new Date(
          Number.parseInt(year.value, 10),
          Number.parseInt(monthValue.value, 10),
        );
        onChange(result);
      } else {
        onChange(undefined);
      }
    },
    [setMonth, onChange, year],
  );

  const onYearChange = React.useCallback(
    (yearValue: SearchPageAttribute) => {
      setYear(yearValue);
      if (month !== initialMonth && yearValue !== initialYear) {
        let result = new Date(
          Number.parseInt(yearValue.value, 10),
          Number.parseInt(month.value, 10),
        );
        onChange(result);
      } else {
        onChange(undefined);
      }
    },
    [setYear, onChange, month],
  );

  return (
    <Box>
      {props.displayHeadline && (
        <DefaultItemLabel
          header={t(props.subHeader) + (props.required ? ' *' : '')}
        />
      )}

      <Grid container columnSpacing={'10px'}>
        <Grid item xs={7} sm={'auto'}>
          <Box width={{ sm: '204px' }}>
            <DefaultItemInputSelect
              values={months}
              maxDropDownHeight
              placeholder={t('Labels.' + props.placeholder)}
              onChanged={onMonthChange}
              value={month}
            />
          </Box>
        </Grid>

        <Grid item xs={5} sm={'auto'}>
          <Box width={{ sm: '104px' }}>
            <DefaultItemInputSelect
              values={years}
              placeholder={t('Labels.' + props.placeholder)}
              onChanged={onYearChange}
              value={year}
            />
          </Box>
        </Grid>
      </Grid>
      <DefaultInputError
        error={(meta.modified || meta.touched) && meta.error}
      />
    </Box>
  );
}
