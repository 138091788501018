import Image from '@/web/views/components/image/image';
import React from 'react';
import crosssrpicon from '@/shared/icons/cross-srp-icon.svg';

export const CrossIcon = (
  <Image
    alt="Remove"
    loading={'eager'}
    src={crosssrpicon.src}
    width={18}
    height={18}
  />
);
