import { SearchPageAttribute, SearchPageField } from '@/shared/lib-api';
import { initialSearchAttribute } from '@/shared/store/reducers/SRPSlice';
import { Box } from '@mui/material';
import * as React from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import DefaultInputError from '../defaults/default-input-error';
import DefaultItemInputAutocomplete from '../defaults/default-item-input-autocomplete';
import { DefaultItemLabel } from '../defaults/default-item-label';

export function FinalFormItemInputAutocomplete(props: SearchPageField) {
  const { t } = useTranslation();

  const {
    input: { value, onChange, onFocus },
    meta,
  } = useField<SearchPageAttribute>(props.formName);
  const onValueChanged = React.useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      val: SearchPageAttribute,
    ) => {
      onChange(val?.value ? val : initialSearchAttribute);
      if (val?.value) {
        event.target.focus();
      }
    },
    [onChange],
  );
  return (
    <Box>
      {props.displayHeadline && (
        <DefaultItemLabel
          header={t(props.subHeader) + (props.required ? ' *' : '')}
        />
      )}
      <DefaultItemInputAutocomplete
        onChange={onValueChanged}
        value={value}
        placeholder={props.placeholder}
        fieldUsage={props.fieldUsage}
        attributes={props.attributes}
      />
      <DefaultInputError
        error={
          ((meta.modified && props.attributes.length !== 0) || meta.touched) &&
          meta.error
        }
      />
    </Box>
  );
}
