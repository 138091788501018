import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import {
  FacetType,
  FieldUsage,
  SearchPageAttribute,
  SearchPageField,
} from '@/shared/lib-api';
import { SRPFieldAttributeSortOrder } from '@/shared/lib-api/models/SRPFieldAttributeSortOrder';
import { Grid } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultItemLabel } from '../defaults/default-item-label';
import DefaultItemInputSelect from '../defaults/selects/default-item-input-select';

function DefaultListItemInputSelect(
  props: SearchPageField & { disableMulti?: boolean },
) {
  const multipleSelections =
    props.facetType === FacetType.MultiSelectIntersection ||
    props.facetType === FacetType.MultiSelectUnion;
  var { field, onChange, fieldExamples } = useSearchForm<
    SearchPageAttribute | SearchPageAttribute[] | string
  >(props.formName, multipleSelections ? 700 : 400);
  var { field: fieldSecondary, onChange: onChangeSecondary } = useSearchForm<
    SearchPageAttribute | SearchPageAttribute[] | string
  >(props.formNameSecondary, multipleSelections ? 700 : 400);

  const { t } = useTranslation();

  const getPlaceholder = React.useCallback(
    (secondary: boolean) => {
      if (props.formNameSecondary === undefined) {
        return t('Labels.' + props.placeholder);
      }
      if (secondary) {
        if (props.placeholder !== '') {
          return t('Labels.' + props.placeholder) + ' ' + t('Labels.to');
        }
        return t('Labels.To');
      } else {
        if (props.placeholder !== '') {
          return t('Labels.' + props.placeholder) + ' ' + t('Labels.from');
        }
        return t('Labels.From');
      }
    },
    [props.formNameSecondary, props.placeholder, t],
  );

  const generateSelectItems = React.useCallback(
    (secondary: boolean, oppositeSelectedValue: SearchPageAttribute) => {
      let placeholder = getPlaceholder(secondary);
      let result = props.attributes;
      if (
        !props.disableAutoFilteringValues &&
        props.formNameSecondary !== undefined
      ) {
        if (oppositeSelectedValue) {
          let indexOfSelectedValue = result.findIndex(
            p => p.value === oppositeSelectedValue.value,
          );
          if (
            props.attributeSortOrder === SRPFieldAttributeSortOrder.NameDesc
          ) {
            result = secondary
              ? result.slice(
                  0,
                  indexOfSelectedValue === -1
                    ? undefined
                    : indexOfSelectedValue + 1,
                )
              : result.slice(
                  indexOfSelectedValue === -1 ? 0 : indexOfSelectedValue,
                );
          } else {
            result = secondary
              ? result.slice(indexOfSelectedValue + 1)
              : result.slice(0, indexOfSelectedValue);
          }
        }
      }

      if (!multipleSelections) {
        result = (
          [
            {
              label: placeholder,
              value: '',
            },
          ] as SearchPageAttribute[]
        ).concat(result);
      }
      return result;
    },
    [
      getPlaceholder,
      props.attributes,
      props.disableAutoFilteringValues,
      props.attributeSortOrder,
      props.formNameSecondary,
      multipleSelections,
    ],
  );

  const selectItems = React.useMemo(() => {
    let oppositeSelectedValue = fieldSecondary;
    let result = generateSelectItems(
      false,
      oppositeSelectedValue as SearchPageAttribute,
    );
    if (multipleSelections) {
      let sortedArray = [...result];
      sortedArray.sort(
        (a, b) =>
          (fieldExamples?.find(p => p.value === b.value)?.count ?? 100000) -
          (fieldExamples?.find(p => p.value === a.value)?.count ?? 0),
      );
      return sortedArray;
    }

    return result;
  }, [generateSelectItems, fieldSecondary, multipleSelections, fieldExamples]);

  const selectItemsSecondary = React.useMemo(() => {
    let oppositeSelectedValue = field;
    let result = generateSelectItems(
      true,
      oppositeSelectedValue as SearchPageAttribute,
    );

    return result;
  }, [generateSelectItems, field]);

  const onValueChanged = React.useCallback(
    (value: SearchPageAttribute | SearchPageAttribute[] | string) => {
      onChange(value);
    },
    [onChange],
  );

  const onValueChangedSecondary = React.useCallback(
    (value: SearchPageAttribute | SearchPageAttribute[] | string) => {
      onChangeSecondary(value);
    },
    [onChangeSecondary],
  );

  return (
    <>
      {props.displayHeadline && (
        <DefaultItemLabel header={t(props.subHeader)} />
      )}

      <Grid container columnSpacing={0.5}>
        <Grid item xs={props.formNameSecondary && props.attributes ? 6 : 12}>
          <DefaultItemInputSelect
            disabledMulti={props.disableMulti}
            values={selectItems}
            placeholder={t('Labels.' + props.placeholder)}
            multiple={multipleSelections}
            onChanged={onValueChanged}
            value={field ? field : multipleSelections ? [] : ''}
          />
        </Grid>

        {props.formNameSecondary && props.attributes && (
          <Grid item xs={6}>
            <DefaultItemInputSelect
              values={selectItemsSecondary}
              placeholder={t('Labels.' + props.placeholder)}
              multiple={multipleSelections}
              onChanged={onValueChangedSecondary}
              value={
                fieldSecondary ? fieldSecondary : multipleSelections ? [] : ''
              }
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default React.memo(DefaultListItemInputSelect);
