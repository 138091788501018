import { useScreenSizeContext } from '@/pages/_app';
import {
  SearchPageAttribute,
  SearchPageOptions,
  SearchResultExample,
} from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchContext } from '../../../SRP/search-section';
import icon from '@/shared/icons/info-tooltip-icon.svg';
import Image from '@/web/views/components/image/image';

interface PropsFromParent {
  onChange: (value: SearchPageAttribute[]) => void;
  fieldValue: SearchPageAttribute[];
  examples?: SearchResultExample[];
  attributes: SearchPageAttribute[];
  displayAllExamples?: boolean;
}

function DefaultItemInputCheckBoxes(props: PropsFromParent) {
  const { mode } = useSearchContext();
  const { isMobile } = useScreenSizeContext();
  const { t } = useTranslation();
  const [dataToModify, setDataToModify] = React.useState(props.fieldValue);
  let limitSearch: keyof SearchPageOptions = 'limitSearch';
  let limitSeatsAndDoors: keyof SearchPageOptions = 'limitSeatsAndDoors';
  const isSevenPerson =
    (props.examples !== undefined &&
      props.examples.length !== 0 &&
      props.examples[0].formName === limitSeatsAndDoors) ||
    (props.attributes.length !== 0 &&
      props.attributes.some(p => p.value === '7-personers'));
  const isLimitSearch =
    (props.examples !== undefined &&
      props.examples.length !== 0 &&
      props.examples[0].formName === limitSearch) ||
    (props.attributes.length !== 0 &&
      props.attributes.some(p => p.value === 'kun-nedsat-pris'));
  const isNoRegTax =
    props.attributes.length !== 0 &&
    props.attributes.some(p => p.value === 'noRegTax');

  const isLeasingType =
    props.attributes !== undefined &&
    props.attributes.length !== 0 &&
    props.attributes[0].value === 'finansiel'; // undgår props.examples
  const selectItems = React.useMemo(() => {
    return props.attributes
      .filter(
        attribute =>
          isLimitSearch ||
          isSevenPerson ||
          isLeasingType ||
          ((mode === 'syi' ||
            mode === undefined ||
            props.examples?.find(p => p.value === attribute.value) !==
              undefined) &&
            props.examples?.find(p => p.value === attribute.value)?.count !==
              0),
      )
      .map((opt, index, array) => (
        <Grid
          key={index}
          item
          xs={12}
          sm={
            (mode === 'screen' || mode === 'syi') && array.length > 3 ? 3 : 12
          }>
          <FormControlLabel
            sx={{ alignItems: isNoRegTax ? 'flex-start' : 'center' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (dataToModify && dataToModify.push) {
                let copyOfData = [...dataToModify];
                if (copyOfData.find(p => p.value === opt.value) !== undefined) {
                  copyOfData = copyOfData.filter(v => v.value !== opt.value);
                } else {
                  copyOfData.push(opt);
                }
                props.onChange(copyOfData);
              }
            }}
            checked={
              dataToModify?.find(p => p.value === opt.value) !== undefined
            }
            label={
              <Box
                display="flex"
                alignItems="center"
                justifyContent={'space-around'}>
                <Typography
                  paddingTop={isNoRegTax ? '4px' : 'initial'}
                  whiteSpace={{ xs: 'initial', md: 'nowrap' }}
                  fontSize={{ xs: 16, sm: 14 }}
                  variant="body1">
                  {isNoRegTax ? (
                    <span dangerouslySetInnerHTML={{ __html: t(opt.label) }} />
                  ) : (
                    t(opt.label)
                  )}
                </Typography>
                {((props.examples?.find(p => p.value === opt.value) &&
                  !isLimitSearch &&
                  !isLeasingType) ||
                  props.displayAllExamples) && (
                  <Typography
                    color={Colors.Gray}
                    variant="body1"
                    marginLeft={2}>
                    (
                    {props.examples.find(p => p.value === opt.value)?.count ??
                      0}
                    )
                  </Typography>
                )}
                {isLeasingType && (
                  <Tooltip
                    title={
                      opt.value === 'finansiel'
                        ? 'Du indestår selv for restværdien, og skal efter aftalens udløb selv finde en køber af bilen.'
                        : 'Forhandleren indestår for restværdien, du leverer blot bilen tilbage efter aftalens udløb. Også kendt som privatleasing. '
                    }>
                    <Box display="flex" alignItems="center" marginLeft={2}>
                      <Image alt="info" height={18} width={18} src={icon.src} />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            }
            control={<Checkbox size={!isMobile ? 'small' : 'medium'} />}
          />
        </Grid>
      ));
  }, [dataToModify, props, t, mode]);

  useEffect(() => {
    if (dataToModify !== props.fieldValue) {
      setDataToModify(props.fieldValue);
    }
  }, [dataToModify, props.fieldValue]);

  return (
    <FormGroup>
      <Grid container alignItems="center">
        {selectItems}
      </Grid>
    </FormGroup>
  );
}

export default React.memo(DefaultItemInputCheckBoxes);
