import { Grid } from '@mui/material';
import React, { ReactNode, useCallback } from 'react';
import { SearchMode } from '../../../SRP/search-section';
import { AddToArray } from './add-to-array';
import { ListArray } from './list-array';

interface PropsFromParent<T> {
  listItems: T[];
  newItem: T;
  onAdd: (value: T[]) => void;
  onRemove: (value: T[]) => void;
  children?: ReactNode;
  mode?: SearchMode;
}

export function ArrayInput<T>(props: PropsFromParent<T>) {
  const onAddToArray = useCallback(() => {
    let valueToSet = [...props.listItems];
    valueToSet.push(props.newItem);
    props.onAdd(valueToSet);
  }, [props]);

  const onRemoveFromArray = useCallback(
    (index: number) => {
      let valueToSet = [...props.listItems];
      valueToSet.splice(index, 1);
      props.onRemove(valueToSet);
    },
    [props],
  );

  return (
    <Grid
      columnSpacing={2.5}
      container
      alignItems="center"
      justifyContent={'center'}>
      <ListArray data={props.listItems} onRemoveFromArray={onRemoveFromArray} />
      {props.children}
      {props.mode !== 'syi' && props.mode !== 'seo-srp' && (
        <AddToArray
          onAddToArray={onAddToArray}
          name="Tilføj flere mærker/modeller"
        />
      )}
    </Grid>
  );
}
