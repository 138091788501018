import { FacetType, SearchPageField } from '@/shared/lib-api';
import { Box } from '@mui/material';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import DefaultItemInputCheckBoxes from '../defaults/default-item-input-checkbox';
import { DefaultItemLabel } from '../defaults/default-item-label';
import * as React from 'react';
import { useScreenSizeContext } from '@/pages/_app';

export function FinalFormItemInputCheckBoxes(props: SearchPageField) {
  const { t } = useTranslation();
  const screenSize = useScreenSizeContext();
  return (
    <Box
      paddingTop={
        (props.formName === 'displayBud' || props.formName === 'hideAddress') &&
        !screenSize.isMobile &&
        '23px'
      }>
      {props.displayHeadline && (
        <DefaultItemLabel
          header={t(props.subHeader) + (props.required ? ' *' : '')}
        />
      )}
      <Field name={props.formName}>
        {field => (
          <DefaultItemInputCheckBoxes
            onChange={field.input.onChange}
            attributes={props.attributes}
            fieldValue={field.input.value}
            displayAllExamples={props.facetType === FacetType.MultiSelectUnion}
          />
        )}
      </Field>
    </Box>
  );
}
