import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { SearchPageField, SearchPageOptions } from '@/shared/lib-api';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultItemInput from '../defaults/default-item-input';
import { DefaultItemLabel } from '../defaults/default-item-label';
import { GeoLocationButton } from '../geo-location-button';

function DefaultListItemTextInput(
  props: SearchPageField & { disable?: boolean },
) {
  return <DefaultListItemInput {...props} type="text" />;
}

export function DefaultListItemNumberInput(
  props: SearchPageField & { disable?: boolean },
) {
  return <DefaultListItemInput {...props} type="number" />;
}

function DefaultListItemInput(
  props: SearchPageField & {
    type: React.HTMLInputTypeAttribute;
    multiline?: boolean;
    disable?: boolean;
  },
) {
  var { field, onChange } = useSearchForm(props.formName, 800);

  const zipCodeField: keyof SearchPageOptions = useMemo(() => {
    return 'zipCode';
  }, []);

  const isZipCodeField = React.useMemo(() => {
    return props.formName === zipCodeField;
  }, [props.formName, zipCodeField]);

  const changeValue = React.useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  const onValueChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      changeValue(event.target.value);
    },
    [changeValue],
  );
  const { t } = useTranslation();
  return (
    <>
      {props.displayHeadline && (
        <DefaultItemLabel header={t(props.subHeader)} />
      )}
      <DefaultItemInput
        value={field}
        disable={props.disable}
        placeholder={t('Labels.' + props.placeholder)}
        postfix={props.postfix}
        multiline={props.multiline}
        onChange={onValueChanged}
        type={props.type}>
        {isZipCodeField && <GeoLocationButton onChange={changeValue} />}
      </DefaultItemInput>
    </>
  );
}

export default React.memo(DefaultListItemTextInput);
