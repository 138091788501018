import { SearchPageField } from '@/shared/lib-api';
import { useAppSelector } from '@/shared/store/hooks';
import * as React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import DefaultInputError from '../defaults/default-input-error';
import DefaultItemInput from '../defaults/default-item-input';
import { DefaultItemLabel } from '../defaults/default-item-label';

export function FinalFormItemTextInput(
  props: SearchPageField & { disable?: boolean },
) {
  return <FinalFormItemInput {...props} type="text" />;
}

export function FinalFormItemNumberInput(
  props: SearchPageField & { disable?: boolean },
) {
  return <FinalFormItemInput {...props} type="number" />;
}

export function FinalFormItemTextAreaInput(
  props: SearchPageField & { disable?: boolean },
) {
  return <FinalFormItemInput {...props} type="text" multiline />;
}

function FinalFormItemInput(
  props: SearchPageField & {
    type: React.HTMLInputTypeAttribute;
    multiline?: boolean;
    disable?: boolean;
  },
) {
  const { t } = useTranslation();
  const loading = useAppSelector(p => p.syiReducer.extendedAutoFillDataLoading);
  return (
    <Field name={props.formName}>
      {field => (
        <>
          {props.displayHeadline && (
            <DefaultItemLabel
              header={t(props.subHeader) + (props.required ? ' *' : '')}
            />
          )}
          <DefaultItemInput
            loadingAutoFillData={props.autoFillDataFromExtended && loading}
            value={field.input.value}
            disable={props.disable}
            placeholder={t('Labels.' + props.placeholder)}
            postfix={props.postfix}
            multiline={props.multiline}
            onBlur={field.input.onBlur}
            onChange={field.input.onChange}
            type={props.type}
          />
          <DefaultInputError
            error={
              (field.meta.modified || field.meta.touched) && field.meta.error
            }
          />
        </>
      )}
    </Field>
  );
}
