import {
  FacetType,
  SearchPageAttribute,
  SearchPageField,
} from '@/shared/lib-api';
import { useAppSelector } from '@/shared/store/hooks';
import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import DefaultInputError from '../defaults/default-input-error';
import { DefaultItemLabel } from '../defaults/default-item-label';
import DefaultItemInputSelect from '../defaults/selects/default-item-input-select';

export function FinalFormItemInputSelect(props: SearchPageField) {
  const loading = useAppSelector(p => p.syiReducer.extendedAutoFillDataLoading);
  const multipleSelections =
    props.facetType === FacetType.MultiSelectIntersection ||
    props.facetType === FacetType.MultiSelectUnion;

  const { t } = useTranslation();

  const getPlaceholder = React.useCallback(
    (secondary: boolean) => {
      if (props.formNameSecondary === undefined) {
        return t('Labels.' + props.placeholder);
      }
      if (secondary) {
        if (props.placeholder !== '') {
          return t('Labels.' + props.placeholder) + ' ' + t('Labels.to');
        }
        return t('Labels.To');
      } else {
        if (props.placeholder !== '') {
          return t('Labels.' + props.placeholder) + ' ' + t('Labels.from');
        }
        return t('Labels.From');
      }
    },
    [props.formNameSecondary, props.placeholder, t],
  );

  const generateSelectItems = React.useCallback(
    (secondary: boolean) => {
      let placeholder = getPlaceholder(secondary);
      let result = props.attributes;

      if (!multipleSelections) {
        result = (
          [
            {
              label: placeholder,
              value: '',
            },
          ] as SearchPageAttribute[]
        ).concat(result);
      }
      return result;
    },
    [getPlaceholder, props.attributes, multipleSelections],
  );

  const selectItems = React.useMemo(() => {
    let result = generateSelectItems(false);

    return result;
  }, [generateSelectItems]);

  return (
    <Box>
      {props.displayHeadline && (
        <DefaultItemLabel
          header={t(props.subHeader) + (props.required ? ' *' : '')}
        />
      )}

      <Grid container columnSpacing={0.5}>
        <Grid item xs={props.formNameSecondary && props.attributes ? 6 : 12}>
          <Field name={props.formName}>
            {field => (
              <>
                <DefaultItemInputSelect
                  loadingAutoFillData={
                    props.autoFillDataFromExtended && loading
                  }
                  values={selectItems}
                  placeholder={t('Labels.' + props.placeholder)}
                  multiple={multipleSelections}
                  onChanged={field.input.onChange}
                  value={
                    field.input.value
                      ? field.input.value
                      : multipleSelections
                      ? []
                      : ''
                  }
                />
                <DefaultInputError
                  error={
                    (field.meta.modified || field.meta.touched) &&
                    field.meta.error
                  }
                />
              </>
            )}
          </Field>
        </Grid>
      </Grid>
    </Box>
  );
}
